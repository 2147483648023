import React from "react"
import { Link, withRouter} from "react-router-dom"

class About extends React.Component {

	render() {
		return (
			<React.Fragment>
				<Link to="/" className="link-back">Back</Link>
				<div className="fullpage">
					<h1>Currency conversion made simple</h1>
					<p>Almost 10 years since the launch of this deadly simple currency converter. Everything is new except the believe in
					serving our visitors with a really neat currency converter.</p>
					<h3>How to</h3>
					<p></p>
					<p>Compare between 32 different currencies</p>
				</div>
			</React.Fragment>
		);
	}
}
export default withRouter(About)