import React from "react"
import { Link, withRouter} from "react-router-dom"

import logo from "./images/crrncy-logotype.svg";

class Header extends React.Component {
	handleClick(e) {
		e.preventDefault();
		this.props.history.push("/");
		this.props.handleFieldValue("");
	}
	render() {
		return (
			<header className="page-header">
				<Link to="/" onClick={this.handleClick.bind(this)}><img src={logo} className="logotype" alt="Crrncy logotype"/></Link>
			</header>
		);
	}
}
export default withRouter(Header)