import React from 'react'
import { withRouter } from "react-router-dom";

import Cards from "./cards.js"
import Settings from "./settings.js"
import Firstpage from "./firstpage.js"

class Converter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			amount: this.props.match.params.amount,
		}
		this.handleChange = this.handleChange.bind(this)
	}
	handleFocus(e) {
		e.target.focus();
		e.target.select();
	}
	handleChange(e) {
		if(e.target.value) {
			var amount = e.target.value;
			this.state.amount = amount
			/*this.setState({
				amount: e.target.value
			})*/
			this.props.history.push("/" + amount);
		}
		else {
			this.state.amount = "";
			/*this.setState = {
				amount: ""
			}*/
			this.props.history.push("/");
		}
	}
	handleViews() {
		if(this.props.match.params.amount) {
			return (
				<Cards currencies={this.props.currencies} amount={this.state.amount} view={"results"} />
			)
		}
		else {
			return (
				<Firstpage />
			)
		}
	}
	render() {
		return (
			<React.Fragment>
				<Settings updateCurrencyList={this.props.updateCurrencyList} currencies={ this.props.currencies } />
				<div className="input-field">
					<input
						id="amount"
						type="number"
						onFocus={this.handleFocus.bind(this)}
						onChange={this.handleChange.bind(this)}
						autoComplete="off"
						value={this.props.amounty === "" ? "" : this.props.match.params.amount}
					/>
				</div>
				{this.handleViews()}
			</React.Fragment>
		)
	}
}
export default withRouter(Converter);