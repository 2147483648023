import React from 'react'
import Currencies from './currencies.json';

class Settings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name1: "",
			name2: "",
		}
	}
	handleChange = (e) => {
		this.props.updateCurrencyList(e.target.checked,e.target.name);
	};
	render() {

		var currencyName1;
		var currencyName2;

		if(this.props.currencies.length <= 2) {
			if(this.props.currencies[0]) {
				currencyName1 = this.props.currencies[0]
			}
			if(this.props.currencies[1]) {
				currencyName2 = this.props.currencies[1]
			}
		}
		else {
			currencyName1 = "";
			currencyName2 = "";
		}
		var currencies = Currencies.map((currency,index) => {
			return (
				<React.Fragment key={index}>
					<input type="checkbox" id={currency.currency + "-" + index} name={currency.currency}
						onChange={this.handleChange}
						disabled={currencyName1 === currency.currency ||currencyName2 === currency.currency} 
						defaultChecked={currencyName1 === currency.currency || currencyName2 === currency.currency}/>
					<label htmlFor={currency.currency + "-" + index}><abbr title={currency.currencyTitle}>{currency.currency}</abbr></label>
				</React.Fragment>
				)
		});

		return (
			<section className="settings">
				{currencies}
			</section>
		)
	}
}
export default Settings