import React from 'react'
import {withRouter} from "react-router-dom"
import ExchangeRates from "./currencies.json"
class Cards extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			"buttonFavorite": "far fa-star",
			"added": false
		}
	}
	handleFavorites(amount, currencies, index, view) {
		if(typeof(Storage) !== "undefined") {
			if(view === "results") {
				// Save for later, kolla om  det redan finns sparat...
				var favorites = {
					saved:
					[
						{amount: "", currencies: ["",""] }
					]
				};
				if(!localStorage.favorites) {
					localStorage.setItem("favorites", JSON.stringify(favorites));
				}
				var stored = JSON.parse(localStorage.getItem("favorites"));
				stored.saved.push({amount: amount, currencies: currencies})

				localStorage.setItem("favorites", JSON.stringify(stored))


				//let btn = (this.state.buttonFavorite === "far fa-star") ? "fas fa-star" : "far fa-star";
    			//this.setState({"buttonFavorite":btn});
    			// Save for later, just one button clicked, today all buttons change classes.
    			//this.setState({"buttonFavorite" : "fas fa-star"})

			}
			else if(view === "favorites") {	
				var stored = JSON.parse(localStorage.getItem("favorites"));
				for(let i = 0; i < stored.saved.length; i++) {
				if(i === index) {
						console.log(stored.saved)
						stored.saved.splice([i],1);
					}
				}
				localStorage.setItem("favorites", JSON.stringify(stored))
				this.props.history.push("/");
			}
		}
		else {
			alert("This service need access to localStorage")
		}
	}

	render() {

		let results = [];
		for (let i = 0; i < this.props.currencies.length - 1; i++) {
			for (let j = i + 1; j < this.props.currencies.length; j++) {
				results.push([this.props.currencies[i],this.props.currencies[j]]);
			}
		}
		var amount = this.props.amount;
		var cards = results.map((currencies,index) => {
			if(amount) {
			return (
				<div className="card" key={amount + index}>
					<table>
						<caption>Conversion between {amount} {(results[index][0]).toUpperCase()} and {(results[index][1]).toUpperCase()}</caption>
						<thead>
							<tr>
								<th className="card-buttons" colSpan="2">
									<button className="icon icon-social" title="Share the conversion between {amount} {(results[index][0]).toUpperCase()} and {(results[index][1]).toUpperCase()}">
										<span className="fas fa-share-alt"></span>
									</button>
									<button className="icon icon-favorite" onClick={this.handleFavorites.bind(this,amount,results[index],this.props.index,this.props.view)}>
										<span className={this.state.buttonFavorite}></span>
									</button>
								</th>
							</tr>
							<tr>
								<th><abbr title="United States dollar">{ results[index][0] }</abbr></th>
								<th><abbr title="Euro">{ results[index][1] }</abbr></th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td><mark className={this.props.view === "results" ? "bounce" : ""}>{ (amount * 1).toFixed(2) }</mark></td>
								<td>
									<output htmlFor={ (results[index][0]) + " amount"}>
										<span className={this.props.view === "results" ? "calc" : ""}>{ (amount  * 0.9).toFixed(2) }</span>
									</output>
								</td>
							</tr>
							<tr>
								<td>
									<output htmlFor={ (results[index][0]) + ' amount'}>
										<span className={this.props.view === "results" ? "calc" : ""}>{ (amount  * 1.1).toFixed(2) }</span>
									</output>
								</td>
								<td><mark className={this.props.view === "results" ? "bounce" : ""}>{ (amount  * 1).toFixed(2) }</mark></td>
							</tr>
						</tbody>
					</table>
				</div>
			)
		}
		else {
			return(null)
		}
		});

		if(this.props.amount) {
			return (
				<React.Fragment>
					{cards}					
				</React.Fragment>
			)
		}
		else {
			return (null)
		}
	}
}
export default withRouter(Cards)