import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Header from "./header.js"
import Converter from "./converter.js"
import Footer from "./footer.js"
import About from "./about.js"
import "./index.css"

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			amount: "",
			currencies: ["usd","eur"]
		}
	}
	updateCurrencyList = (status,val) => {
		if(status) {
			if(!this.state.currencies.includes(val)) {
				this.setState({
					currencies: [...this.state.currencies, val]
				});
			}
		}
		else {
			if(this.state.currencies.includes(val)) {
				var pos = this.state.currencies.indexOf(val);
				this.state.currencies.splice(pos, 1);
			}
			this.setState({
				currencies: this.state.currencies
			});
		}
	}
	handleFieldValue = (val) => {
		this.state.amount = val
	}
	render() {
		return (
			<Router>
				<React.Fragment>
					<Header handleFieldValue={this.handleFieldValue} />
					<Switch>
						<Route exact path="/"
							render={props => <Converter {...props} currencies={ this.state.currencies } updateCurrencyList={this.updateCurrencyList} amounty={this.state.amount} handleFieldValue={this.handleFieldValue.bind(this)} />}
						/>
						<Route path="/about/" component={About} />
						<Route path="/:amount(\d*?[.,]?\d*?)"
							render={props => <Converter {...props} currencies={ this.state.currencies } updateCurrencyList={this.updateCurrencyList} handleFieldValue={this.handleFieldValue.bind(this)} />}
						/>
						<Route component={nomatch} />
					</Switch>
					<Footer />
				</React.Fragment>
			</Router>
		);
	}
}
function nomatch({location}) {
	return (
		<p>No match for <code>{location.pathname}</code></p>
	)
}

export default App;
