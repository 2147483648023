import React from "react"
import { withRouter} from "react-router-dom"

import Cards from "./cards.js"

class FirstPage extends React.Component {

	removeStored = (e) => {
		localStorage.removeItem("favorites");
		this.props.history.push("/");
	}

	render() {

		if(localStorage.favorites) {
			if(JSON.parse(localStorage.getItem("favorites")).saved.length > 0) {
				var arr = JSON.parse(localStorage.getItem("favorites"))

				var cards = arr.saved.map((saved,index) => {
					return(
						<React.Fragment key={index}>
							<Cards currencies={saved.currencies} amount={saved.amount} view={"favorites"} index={index} />
						</React.Fragment>
					)
				});
			

				if(localStorage.favorites.length > 46) {
					return (
						<React.Fragment>
							<h4>Favorite conversions</h4>
							{cards}
						</React.Fragment>
					)
				}
			}
		}
		return (
			<React.Fragment>
				<h4 className="social-twitter"><a href="/"><i class="fab fa-twitter"></i>Follow us on Twitter</a></h4>
			</React.Fragment>
		)
	}
}
export default withRouter(FirstPage)