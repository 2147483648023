import React from "react"
import { Link, withRouter} from "react-router-dom"

class Footer extends React.Component {

	render() {
		return (
			<footer>
				<p>&copy; 2009-2018 <Link to="/">Crrncy</Link></p>
				<ul>
					<li><Link to="/about">About</Link></li>
				</ul>
			</footer>
		);
	}
}
export default withRouter(Footer)